<template>
  <div>
    <Header></Header>

    <div class="elive-container">
      <!-- 内容区 -->
      <AppMain></AppMain>
    </div>

    <!-- 底部 -->
    <Footer></Footer>

    <!-- 登录弹窗 -->
    <Login v-if="currentEnv != 'wx'"></Login>
    <WxBind v-else></WxBind>

    <!-- 注册弹窗 -->
    <Reg></Reg>
    <Finish></Finish>
  </div>
</template>


<script>
import { toRefs, reactive } from "vue";
import Header from "./components/Header.vue";
import Footer from "@/views/visitor/cn/Auth/components/regFooter.vue";
import AppMain from "./components/AppMain.vue";
import Login from "@/views/eventLive/cn/auth/Login.vue";
import WxBind from "@/views/eventLive/cn/auth/WxLogin.vue";
import Reg from "@/views/eventLive/cn/auth/Reg.vue";
import Finish from "@/views/eventLive/cn/auth/Finish.vue";

import { getUserAgent } from "@/utils/common";


export default {
  components: {
    Header,
    Footer,
    AppMain,
    Login,
    WxBind,
    Reg,
    Finish
  }, 
  setup(props) {
    const state = reactive({
      currentEnv: getUserAgent(),
    })

    return { 
      ...toRefs(state),
     };
  },

};
</script>