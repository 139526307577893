import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import showMsg from "@/utils/message";
import Cookies from "js-cookie";
import request from "@/utils/request";


import Layout from "@/views/visitor/cn/Layout/Index.vue";
import LayoutEn from "@/views/visitor/en/Layout/Index.vue";

import liveLayout from "@/views/eventLive/cn/layout/Index.vue";
import liveLayoutEn from "@/views/eventLive/en/layout/Index.vue";

const Login = () => import("@/views/visitor/cn/Auth/Login.vue");
const LoginEn = () => import("@/views/visitor/en/Auth/Login.vue");
const Reg = () => import("@/views/visitor/cn/Auth/Reg.vue");
const RegEn = () => import("@/views/visitor/en/Auth/Reg.vue");

// 正式 /en 重定向
const hostname = ["visitor.sialchina.cn", "visitor.sialchina.com"];
if(window.location.pathname.indexOf("/en") == 0 && hostname.includes(window.location.hostname)) {
  window.location.href = process.env.VUE_APP_EN_HOST + window.location.pathname.replace(/^(\/en)/,"");
}

let currLang = "cn";
let langPath = "/en";
if(window.location.hostname == "visitor.sialchina.com") {
  langPath = ""
}
if(window.location.hostname == "visitor.sialchina.com" || window.location.pathname.indexOf("/en") == 0) {
  currLang = "en"
}
/**
 * roles: ["visitor"] 页面可访问角色设置 visitor(观众、媒体、特邀买家): 是预登记会员； ordinary: 普通会员; vip
 */

const routes_cn = [

  { path: "/wechat", name: "wechat", component: () => import("@/views/visitor/cn/Wechat/index.vue"), hidden: true },
  { path: "/wxbind", name: "wxbind", component: () => import("@/views/visitor/cn/Auth/WxBinding.vue"), hidden: true },

  { path: "/logintest", name: "logintest", component: () => import("@/views/visitor/cn/Auth/Logintest.vue"), hidden: true },

  { path: "/login", name: "login", component: Login, hidden: true },
  { path: "/login.html", name: "login2", component: Login, hidden: true },
  { path: "/visitor/login", name: "login3", component: Login, hidden: true },
  { path: "/visitor/login.html", name: "login4", component: Login, hidden: true },
  { path: "/reg", name: "reg", component: Reg, hidden: true },
  { path: "/reg.html", name: "reg2", component: Reg, hidden: true },
  { path: "/visitor/reg", name: "reg3", component: Reg, hidden: true },
  { path: "/visitor/reg.html", name: "reg24", component: Reg, hidden: true },
  // VIP
  { 
    path: "/reg/vip/:recommend_user_id(\\d+)", 
    name: "regVip", 
    component: Reg,
    hidden: true 
  },
  // 特邀买家
  { 
    path: "/matchme/reg", 
    name: "regInvite", 
    component: Reg,
    hidden: true 
  },
  { 
    path: "/forget", 
    name: "forget", 
    component: () => import("@/views/visitor/cn/Auth/Forget.vue"), 
    hidden: true 
  },
  { 
    path: "/reg_survey/:expo_area_ids", 
    name: "reg_survey", 
    component: () => import("@/views/visitor/cn/Auth/Survey.vue"), 
    hidden: true 
  },
  { 
    path: "/reg_finish/:expo_area_ids", 
    name: "reg_finish", 
    component: () => import("@/views/visitor/cn/Auth/Finish.vue"), 
    hidden: true 
  },
  // 万能码绑定门票
  { 
    path: "/universal", 
    name: "regUniversal", 
    component: () => import("@/views/visitor/cn/Auth/UniversalCodeReg.vue"), 
    hidden: true 
  },
  { 
    path: "/policy", 
    name: "policy_cn", 
    component: () => import("@/views/visitor/cn/Auth/PolicyPage.vue"), 
    hidden: true 
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/cn/Index/Index.vue"),
        name: "Index",
        meta: { title: "概览", icon: "icon-shouye", chkUserInfo: true, breadcrumb: false },
      },
    ],
  },

  // 信息订阅落地页
  { 
    path: "/info_subscription", 
    name: "info_subscription", 
    component: () => import("@/views/visitor/cn/Subscription/Landing.vue"),
    hidden: true 
  },

  // 峰会
  {
    path: "/eventlive",
    component: liveLayout,
    hidden: true,
    children: [
      {
        path: "",
        component: () => import("@/views/eventLive/cn/Index.vue"),
        name: "",
        meta: { title: "首页", icon: "icon-shouye", showFooter: true, index: 1 },
      },
      {
        path: "/eventlive.html",
        component: () => import("@/views/eventLive/cn/Index.vue"),
        name: "",
        meta: { title: "首页", icon: "icon-shouye", showFooter: true, index: 1 },
      },
      // type: 线下onsite, 直播live
      // host: 主办方 sponsor, 观众：visitor
      {
        path: "/take-ticket/:host/:type/:give_id(\\d+)/:receive_code",
        component: () => import("@/views/eventLive/cn/TakeTicket.vue"),
        name: "tackTicket",
        meta: { title: "领取赠票", showFooter: false, show_nav: 0, index: 1},
      },
      {
        path: "/forumticket",
        component: () => import("@/views/eventLive/cn/OnsiteForums/Index.vue"),
        name: "onsiteForums",
        meta: { title: "线下峰会", showFooter: true, index: 2},
      },
      {
        path: "/forumticket/:id(\\d+)",
        component: () => import("@/views/eventLive/cn/OnsiteForums/Info.vue"),
        name: "",
        meta: { title: "线下峰会详情", showFooter: true, index: 2},
      },
      {
        path: "/forumticket/:id(\\d+).html",
        component: () => import("@/views/eventLive/cn/OnsiteForums/Info.vue"),
        name: "",
        meta: { title: "线下峰会详情", showFooter: true, index: 2},
      },
      {
        path: "livelist",
        component: () => import("@/views/eventLive/cn/liveList/Index.vue"),
        name: "liveList",
        meta: { title: "直播峰会", showFooter: true, index: 3},
      },
      {
        path: "livelist/info/:id(\\d+)",
        component: () => import("@/views/eventLive/cn/liveList/Info.vue"),
        name: "",
        meta: { title: "直播峰会详情", showFooter: true, index: 3},
      },
      {
        path: "livelist/info/:id(\\d+).html",
        component: () => import("@/views/eventLive/cn/liveList/Info.vue"),
        name: "",
        meta: { title: "直播峰会详情", showFooter: true, index: 3},
      },
      {
        path: "master",
        component: () => import("@/views/eventLive/cn/liveList/Master.vue"),
        name: "master",
        meta: { title: "直播峰会", showFooter: true, index: 4},
      },
      
      {
        path: "/wxpay/live/:order_id(\\d+)",
        component: () => import("@/components/LivePay/wxpay.vue"),
        meta: { title: "线下峰会微信支付"},
        hidden: true,
      },
      
      {
        path: "/wxpay/onsiteforums/:ticket_id(\\d+)",
        component: () => import("@/components/OnsiteForumsPay/wxpay.vue"),
        meta: { title: "线下峰会微信支付"},
        hidden: true,
      },
    ],
  },

  // 门票核销
  {
    path: "/verification",
    hidden: true,
    children: [
      {
        path: "index/:expo",
        component: () => import("@/views/visitor/cn/Verification/index.vue"),
        name: "verification",
        meta: { title: "门票核销"},
      },
      {
        path: "content/:expo",
        component: () => import("@/views/visitor/cn/Verification/content.vue"),
        name: "verification",
        meta: { title: "门票核销"},
      },
    ]
  },

  {
    path: "/alipay/onsiteforums/:ticket_id(\\d+)",
    component: () => import("@/components/OnsiteForumsPay/alipay.vue"),
    meta: { title: "线下峰会支付宝支付"},
    hidden: true,
  },
  {
    path: "/alipay/live/:order_id(\\d+)",
    component: () => import("@/components/LivePay/alipay.vue"),
    meta: { title: "峰会直播支付宝支付"},
    hidden: true,
  },
  // 访问不存在路由跳转到/
  {
    path: "/eventlive/:pathMatch(.*)*",
    redirect: "/eventlive",
  },
];

const routes_en = [

  { path: `${langPath}/login`, name: "login_en", component: LoginEn, hidden: true, lang: "en" },
  { path: `${langPath}/login.html`, name: "login_en2", component: LoginEn, hidden: true, lang: "en" },
  { path: `/visitor${langPath}/login`, name: "login_en3", component: LoginEn, hidden: true, lang: "en" },
  { path: `/visitor${langPath}/login.html`, name: "login_en4", component: LoginEn, hidden: true, lang: "en" },
  
  { path: `${langPath}/reg`, name: "reg_en", component: RegEn, hidden: true, lang: "en" },
  { path: `${langPath}/reg.html`, name: "reg_en2", component: RegEn, hidden: true, lang: "en" },
  { path: `/visitor${langPath}/reg`, name: "reg_en3", component: RegEn, hidden: true, lang: "en" },
  { path: `/visitor${langPath}/reg.html`, name: "reg_en4", component: RegEn, hidden: true, lang: "en" },
 
  // VIP
  { 
    path: `${langPath}/reg/vip/:recommend_user_id(\\d+)`, 
    name: "regVip_en", 
    component: RegEn,
    hidden: true ,
    lang: "en"
  },
  // 特邀买家
  { 
    path: `${langPath}/matchme/reg`, 
    name: "regInvite_en", 
    component: RegEn,
    hidden: true ,
    lang: "en"
  },
  { 
    path: `${langPath}/forget`, 
    name: "forget_en", 
    component: () => import("@/views/visitor/en/Auth/Forget.vue"), 
    hidden: true ,
    lang: "en"
  },
  { 
    path: `${langPath}/reg_survey/:expo_area_ids`, 
    name: "reg_survey_en", 
    component: () => import("@/views/visitor/en/Auth/Survey.vue"), 
    hidden: true ,
    lang: "en"
  },
  { 
    path: `${langPath}/reg_finish/:expo_area_ids`, 
    name: "reg_finish_en", 
    component: () => import("@/views/visitor/en/Auth/Finish.vue"), 
    hidden: true ,
    lang: "en"
  },
  { 
    path: `${langPath}/policy`, 
    name: "policy_en", 
    component: () => import("@/views/visitor/en/Auth/PolicyPage.vue"), 
    hidden: true 
  },
  {
    path: langPath == "" ? "/" : "/en",
    component: LayoutEn,
    lang: "en",
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/en/Index/Index.vue"),
        name: "Index_en",
        meta: { title: "Home", icon: "icon-shouye", chkUserInfo: true, breadcrumb: false},
        lang: "en"
      },
    ],
  },

  // 信息订阅落地页
  { 
    path: `${langPath}/info_subscription`, 
    name: "info_subscription_en", 
    component: () => import("@/views/visitor/en/Subscription/Landing.vue"),
    hidden: true 
  },

  // 峰会
  {
    path: `${langPath}/eventlive`,
    component: liveLayoutEn,
    hidden: true,
    lang: "en",
    children: [
      {
        path: "",
        component: () => import("@/views/eventLive/en/Index.vue"),
        name: "",
        meta: { title: "Home", icon: "icon-shouye", showFooter: true, index: 1,  },
        lang: "en"
      },
      {
        path: `${langPath}/eventlive.html`,
        component: () => import("@/views/eventLive/en/Index.vue"),
        name: "",
        meta: { title: "Home", icon: "icon-shouye", showFooter: true, index: 1,  },
        lang: "en"
      },
      // type: 线下onsite, 直播live
      // host: 主办方 sponsor, 观众：visitor
      {
        path: `${langPath}/take-ticket/:host/:type/:give_id(\\d+)/:receive_code`,
        component: () => import("@/views/eventLive/en/TakeTicket.vue"),
        name: "tackTicket_en",
        meta: { title: "领取赠票", showFooter: false, show_nav: 0, index: 1, },
        lang: "en"
      },
      {
        path: `${langPath}/forumticket`,
        component: () => import("@/views/eventLive/en/OnsiteForums/Index.vue"),
        name: "onsiteForums_en",
        meta: { title: "Summit Tickets", showFooter: true, index: 2, },
        lang: "en"
      },
      {
        path: `${langPath}/forumticket/:id(\\d+)`,
        component: () => import("@/views/eventLive/en/OnsiteForums/Info.vue"),
        name: "",
        meta: { title: "Summit Tickets", showFooter: true, index: 2, },
        lang: "en"
      },
      {
        path: `${langPath}/forumticket/:id(\\d+).html`,
        component: () => import("@/views/eventLive/en/OnsiteForums/Info.vue"),
        name: "",
        meta: { title: "Summit Tickets", showFooter: true, index: 2, },
        lang: "en"
      },
      {
        path: "livelist",
        component: () => import("@/views/eventLive/en/liveList/Index.vue"),
        name: "liveList_en",
        meta: { title: "Live Streaming", showFooter: true, index: 3, },
        lang: "en"
      },
      {
        path: "livelist/info/:id(\\d+)",
        component: () => import("@/views/eventLive/en/liveList/Info.vue"),
        name: "",
        meta: { title: "Live Streaming", showFooter: true, index: 3, },
        lang: "en"
      },
      {
        path: "livelist/info/:id(\\d+).html",
        component: () => import("@/views/eventLive/en/liveList/Info.vue"),
        name: "",
        meta: { title: "Live Streaming", showFooter: true, index: 3, },
        lang: "en"
      },
      {
        path: "master",
        component: () => import("@/views/eventLive/en/liveList/Master.vue"),
        name: "master_en",
        meta: { title: "Live Streaming", showFooter: true, index: 4, },
        lang: "en"
      },
    ],
  },
  {
    path: `${langPath}/alipay/onsiteforums/:ticket_id(\\d+)`,
    component: () => import("@/components/OnsiteForumsPay/alipay.vue"),
    meta: { title: "线下峰会支付宝支付"},
    hidden: true,
    lang: "en"
  },
  {
    path: `${langPath}/alipay/live/:order_id(\\d+)`,
    component: () => import("@/components/LivePay/alipay.vue"),
    meta: { title: "峰会直播支付宝支付",},
    hidden: true,
    lang: "en",
  },

  
  // 访问不存在路由跳转到/
  {
    path: `${langPath}/eventlive/:pathMatch(.*)*`,
    redirect: `${langPath}/eventlive`,
  },
  
  // {
  //   path: "https://visitor.sialchina.com/en/eventlive:pathMatch(.*)*",
  //   redirect: "https://visitor.sialchina.com/eventlive",
  // },
];

export const constantRoutes = currLang == 'en' ? routes_en : routes_cn;
// console.log("constantRoutes: ",constantRoutes);
// 权限路由
const asyncRoutesCn = [
  // 会员信息
  {
    path: "/profile",
    component: Layout,
    meta: { title: "会员信息", icon: "icon-user" },
    children: [
      {
        path: ":expo_area_id(\\d+)?",
        component: () => import("@/views/visitor/cn/Profile/Index.vue"),
        name: "Profile",
        meta: { title: "个人信息"},
      },
      // {
      //   path: "/researchInfo",
      //   hidden: true,
      //   component: () => import("@/views/visitor/cn/Profile/researchInfo.vue"),
      //   meta: { title: "调研信息"},
      // },
      {
        path: "/changepwd",
        component: () => import("@/views/visitor/cn/Profile/changePwd.vue"),
        name: "Changepwd",
        meta: { title: "修改密码"},
      },
    ],
  },

  // 参观信息
  {
    path: "/confirmation",
    component: Layout,
    meta: { 
      title: "参观信息", 
      icon: "icon-form",
      roles: ["visitor"]
    },
    children: [
      {
        path: ":expo_area_id?",
        component: () => import("@/views/visitor/cn/Fair/Confirmation.vue"),
        meta: { title: "参观确认函", chkUserInfo: true},
        name: "Confirmation"
      },
      {
        path: "/survey/:expo_area_ids?",
        component: () => import("@/views/visitor/cn/Fair/Survey.vue"),
        meta: { title: "问卷信息"},
      },
    ],
  },

  // 邮件订阅
  {
    path: "/subscription",
    component: Layout,
    // hidden: true,
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/cn/Subscription/Index.vue"),
        meta: { title: "信息订阅", icon: "icon-youjian", chkUserInfo: true,
        roles: ["visitor","ordinary"] },
      },
    ],
  },

  // 商贸配对
  {
    path: "/matching",
    component: Layout,
    meta: { 
      title: "商贸配对", 
      icon: "icon-goutong",
      chkUserInfo: true,
      roles: ["visitor"]
    },
    // hidden: true,
    children: [
      {
        path: "form",
        component: () => import("@/views/visitor/cn/Booking/formInfo.vue"),
        meta: { title: "配对信息"},
      },
      {
        path: "",
        component: () => import("@/views/visitor/cn/Booking/Index.vue"),
        meta: { title: "在线预约"},
      },
      {
        path: "/matching/booking/sent",
        meta: { title: "预约管理"},
        children: [
          {
            path: "/matching/booking/sent",
            component: () => import("@/views/visitor/cn/Booking/myBooking.vue"),
            meta: { title: "发起的约见"},
            hidden: true
          },
          {
            path: "/matching/booking/receive",
            component: () => import("@/views/visitor/cn/Booking/receiveBooking.vue"),
            meta: { title: "收到的约见"},
            hidden: true
          },
          {
            path: "/matching/booking/schedule",
            component: () => import("@/views/visitor/cn/Booking/schedule.vue"),
            meta: { title: "我的日程"},
            hidden: true
          },
        ]
      },
      {
        path: "/matching/favlist/exhibitor",
        meta: { title: "我的收藏"},
        children: [
          {
            path: "/matching/favlist/exhibitor",
            component: () => import("@/views/visitor/cn/Booking/favlist_exh.vue"),
            meta: { title: "收藏展商"},
            hidden: true
          },
          {
            path: "/matching/favlist/products",
            component: () => import("@/views/visitor/cn/Booking/favlist_products.vue"),
            meta: { title: "收藏展品"},
            hidden: true
          },
        ]
      },
    ],
  },


  // 邀请管理
  {
    path: "/invite",
    component: Layout,
    meta: { 
      title: "邀请管理", 
      icon: "icon-yaoqing",
      chkUserInfo: true,
      roles: ["visitor"]
    },
    
    children: [
      {
        path: "recommend",
        component: () => import("@/views/visitor/cn/Invite/Recommend.vue"),
        meta: { title: "推荐给好友"},
      },
      {
        path: "/pasvreg",
        meta: { title: "协助他人登记"},
        children: [
          {
            path: ":expo_area_id(\\d+)?",
            component: () => import("@/views/visitor/cn/Pasvreg/index.vue"),
            meta: { title: "协助他人登记", breadcrumb: false},
            name: "Pasvreg"
          },
          {
            path: "add/:expo_area_id(\\d+)?",
            component: () => import("@/views/visitor/cn/Pasvreg/Add.vue"),
            meta: { title: "协助他人登记", breadcrumb: false},
            hidden: true
          },
          {
            path: "info/:expo_area_id(\\d+)/:id(\\d+)",
            component: () => import("@/views/visitor/cn/Pasvreg/Info.vue"),
            meta: { title: "协助他人登记", breadcrumb: false},
            hidden: true
          },
          {
            path: "group/:expo_area_id(\\d+)?",
            component: () => import("@/views/visitor/cn/Pasvreg/Group.vue"),
            meta: { title: "团体导入"},
            hidden: true
          },
          {
            path: "group/detail/:expo_area_id(\\d+)/:batch_id",
            component: () => import("@/views/visitor/cn/Pasvreg/groupDetail.vue"),
            meta: { title: "团体导入"},
            hidden: true
          },
        ]
      },
    ]
  },

  // 峰会直播
  {
    path: "/live",
    component: Layout,
    meta: { 
      title: "峰会直播", 
      icon: "icon-icon_zhibo-xian",
      chkUserInfo: true
    },
    // hidden: true,
    
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/cn/Live/Index.vue"),
        meta: { title: "峰会直播"},
      },
      {
        path: "info/:id(\\d+)",
        component: () => import("@/views/visitor/cn/Live/Info.vue"),
        meta: { title: "峰会直播"},
        hidden: true,
      },
      {
        path: "orders",
        component: () => import("@/views/visitor/cn/Live/Orders.vue"),
        meta: { title: "我的订单"},
      },
      {
        path: "freeticket",
        component: () => import("@/views/visitor/cn/Live/Freeticket/Index.vue"),
        meta: { title: "我的赠票"},
      },
      {
        path: "freeticket/info/:give_id(\\d+)",
        component: () => import("@/views/visitor/cn/Live/Freeticket/Info.vue"),
        meta: { title: "赠票领取人"},
        hidden: true,
      },
      {
        path: "reservation",
        component: () => import("@/views/visitor/cn/Live/Reservation.vue"),
        meta: { title: "我的订阅"},
      },
    ]
  },

  // 线下峰会
  {
    path: "/onsite",
    component: Layout,
    meta: { 
      title: "线下峰会", 
      icon: "icon-outline-message-question",
      chkUserInfo: true
    },
    hidden: true,
    
    children: [
      {
        path: "forums",
        component: () => import("@/views/visitor/cn/Onsite/Index.vue"),
        meta: { title: "峰会购票"},
      },
      {
        path: "forums/:id(\\d+)",
        component: () => import("@/views/visitor/cn/Onsite/Info.vue"),
        meta: { title: "峰会购票"},
        hidden: true,
      },
      {
        path: "orders",
        component: () => import("@/views/visitor/cn/Onsite/Orders.vue"),
        meta: { title: "我的订单"},
      },
      {
        path: "tickets",
        component: () => import("@/views/visitor/cn/Onsite/Tickets.vue"),
        meta: { title: "我的门票"},
      },
      {
        path: "noredeemed",
        component: () => import("@/views/visitor/cn/Onsite/noRedeem.vue"),
        name: "",
        meta: { title: "未兑换门票",},
      },

      {
        path: "freeticket",
        component: () => import("@/views/visitor/cn/Onsite/Freeticket/Index.vue"),
        meta: { title: "我的赠票"},
      },
      {
        path: "freeticket/info/:ticket_id(\\d+)",
        component: () => import("@/views/visitor/cn/Onsite/Freeticket/Info.vue"),
        meta: { title: "我的赠票",},
        hidden: true,
      },
    ]
  },

  // 白皮书下载
  {
    path: "/download",
    component: Layout,
    meta: { 
      title: "白皮书下载", 
      icon: "icon-shu2",
      chkUserInfo: true,
      roles: ["visitor","ordinary"]
    },
    
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/cn/Download/Index.vue"),
        meta: { title: "白皮书下载",icon: "icon-shu2",},
      },
      {
        path: "/mydownload",
        component: () => import("@/views/visitor/cn/Download/myDownload.vue"),
        meta: { title: "我的下载",},
        hidden: true,
      },
    ]
  },

  // 沙龙
  {
    path: "/salon",
    component: Layout,
    meta: { 
      title: "沙龙报名", 
      icon: "icon-lianxiwomen",
      chkUserInfo: true,
      roles: ["visitor","ordinary"]
    },
    
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/cn/Salon/Index.vue"),
        meta: { title: "沙龙报名",icon: "icon-lianxiwomen",},
      },
      {
        path: "/mysalon",
        component: () => import("@/views/visitor/cn/Salon/Mysalon.vue"),
        meta: { title: "沙龙报名"},
        hidden: true,
      },
      
    ]
  },

  // 发票申请
  {
    path: "/invoice",
    component: Layout,
    meta: { 
      title: "发票申请", 
      icon: "icon-fapiaoguanli",
      chkUserInfo: true
    },
    
    children: [
      {
        path: ":source_type?",
        component: () => import("@/views/visitor/cn/Invoice/Index.vue"),
        meta: { title: "发票申请",icon: "icon-fapiaoguanli",},
      },
      {
        path: "add/:source_type(\\d+)/:id(\\d+)",
        component: () => import("@/views/visitor/cn/Invoice/Add.vue"),
        meta: { title: "发票申请",},
        hidden: true
      },
      {
        path: "info/:source_type(\\d+)/:id(\\d+)",
        component: () => import("@/views/visitor/cn/Invoice/Info.vue"),
        meta: { title: "发票申请",},
        hidden: true
      },
    ]
  },

  // 展馆交通
  {
    path: "/",
    children: [
      {
        path: "https://www.sialchina.cn/contact/arrived.html",
        meta: { title: "展馆交通",icon: "icon-See",
        roles: ["visitor","ordinary"]},
      },
    ]
  },


  // 联系我们
  {
    path: "/",
    children: [
      {
        path: "https://www.sialchina.cn/contact/visitor-media.html",
        meta: { title: "联系主办方",icon: "icon-ziyuan",},
      },
    ]
  },
  
  {
    path: '/visitor/:page',
    redirect: to => {
      const page = to.params.page;
      return page
    },
  },
  // 访问不存在路由跳转到/
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const asyncRoutesEn = [
  // 会员信息
  {
    path: `${langPath}/profile`,
    component: LayoutEn,
    meta: { title: "Basic Information", icon: "icon-user", },
    lang: "en",
    children: [
      {
        path: ":expo_area_id(\\d+)?",
        component: () => import("@/views/visitor/en/Profile/Index.vue"),
        name: "Profile_en",
        meta: { title: "Personal Information",},
        lang: "en",
      },
      // {
      //   path: `${langPath}/researchInfo`,
      //   hidden: true,
      //   component: () => import("@/views/visitor/en/Profile/researchInfo.vue"),
      //   meta: { title: "Confirmation Letter"},
        // lang: "en",
      // },
      {
        path: `${langPath}/changepwd`,
        component: () => import("@/views/visitor/en/Profile/changePwd.vue"),
        name: "Changepwd_en",
        meta: { title: "Change Password",},
        lang: "en",
      },
    ],
  },

  // 参观信息
  {
    path: `${langPath}/confirmation`,
    component: LayoutEn,
    meta: { 
      title: "Visit information", 
      icon: "icon-form",
      roles: ["visitor"],
    },
    lang: "en",
    children: [
      {
        path: ":expo_area_id?",
        component: () => import("@/views/visitor/en/Fair/Confirmation.vue"),
        meta: { title: "Confirmation Letter", chkUserInfo: true,},
        name: "Confirmation_en",
        lang: "en",
      },
      {
        path: `${langPath}/survey/:expo_area_ids?`,
        component: () => import("@/views/visitor/en/Fair/Survey.vue"),
        meta: { title: "Questionnaire",},
        lang: "en",
      },
    ],
  },

  // 邮件订阅
  {
    path: `${langPath}/subscription`,
    component: LayoutEn,
    lang: "en",
    hidden: true,
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/en/Subscription/Index.vue"),
        meta: { title: "Mail subscription", icon: "icon-youjian", chkUserInfo: true, roles: ["visitor","ordinary"] ,},
        lang: "en",
      },
    ],
  },

  // 商贸配对
  {
    path: `${langPath}/matching`,
    component: LayoutEn,
    meta: { 
      title: "Matchmaking", 
      icon: "icon-goutong",
      chkUserInfo: true,
      roles: ["visitor"],
    },
    lang: "en",
    // hidden: true,
    children: [
      {
        path: "form",
        component: () => import("@/views/visitor/en/Booking/formInfo.vue"),
        meta: { title: "Matching Information"},
        lang: "en",
      },
      {
        path: "",
        component: () => import("@/views/visitor/en/Booking/Index.vue"),
        meta: { title: "Matchmaking",},
        lang: "en",
      },
      {
        path: `${langPath}/matching/booking/sent`,
        meta: { title: "Booking Management",},
        lang: "en",
        children: [
          {
            path: `${langPath}/matching/booking/sent`,
            component: () => import("@/views/visitor/en/Booking/myBooking.vue"),
            meta: { title: "Appointment sent",},
            lang: "en",
            hidden: true
          },
          {
            path: `${langPath}/matching/booking/receive`,
            component: () => import("@/views/visitor/en/Booking/receiveBooking.vue"),
            meta: { title: "Appointment Received",},
            lang: "en",
            hidden: true
          },
          {
            path: `${langPath}/matching/booking/schedule`,
            component: () => import("@/views/visitor/en/Booking/schedule.vue"),
            meta: { title: "My Schedule",},
            lang: "en",
            hidden: true
          },
        ]
      },
      {
        path: `${langPath}/matching/favlist/exhibitor`,
        meta: { title: "My Collection"},
        lang: "en",
        children: [
          {
            path: `${langPath}/matching/favlist/exhibitor`,
            component: () => import("@/views/visitor/en/Booking/favlist_exh.vue"),
            meta: { title: "Collection exhibitor"},
            lang: "en",
            hidden: true
          },
          {
            path: `${langPath}/matching/favlist/products`,
            component: () => import("@/views/visitor/en/Booking/favlist_products.vue"),
            meta: { title: "Collect exhibits"},
            lang: "en",
            hidden: true
          },
        ]
      },
    ],
  },


  // 邀请管理
  {
    path: `${langPath}/invite`,
    component: LayoutEn,
    meta: { 
      title: "Invite Friends", 
      icon: "icon-yaoqing",
      chkUserInfo: true,
      roles: ["visitor"],
    },
    lang: "en",
    
    children: [
      {
        path: "recommend",
        component: () => import("@/views/visitor/en/Invite/Recommend.vue"),
        meta: { title: "Invite Friends",},
        lang: "en",
      },
      {
        path: `${langPath}/pasvreg`,
        meta: { title: "Register for Friend",},
        lang: "en",
        children: [
          {
            path: ":expo_area_id(\\d+)?",
            component: () => import("@/views/visitor/en/Pasvreg/index.vue"),
            meta: { title: "Register for Friend", breadcrumb: false},
            name: "Pasvreg_en",
            lang: "en",
          },
          {
            path: "add/:expo_area_id(\\d+)?",
            component: () => import("@/views/visitor/en/Pasvreg/Add.vue"),
            meta: { title: "Register for Friend", breadcrumb: false},
            hidden: true,
            lang: "en",
          },
          {
            path: "info/:expo_area_id(\\d+)/:id(\\d+)",
            component: () => import("@/views/visitor/en/Pasvreg/Info.vue"),
            meta: { title: "Register for Friend", breadcrumb: false},
            hidden: true,
            lang: "en",
          },
          {
            path: "group/:expo_area_id(\\d+)?",
            component: () => import("@/views/visitor/en/Pasvreg/Group.vue"),
            meta: { title: "Group import",},
            hidden: true,
            lang: "en",
          },
          {
            path: "group/detail/:expo_area_id(\\d+)/:batch_id",
            component: () => import("@/views/visitor/en/Pasvreg/groupDetail.vue"),
            meta: { title: "Group import",},
            hidden: true,
            lang: "en",
          },
        ]
      },
    ]
  },

  // 峰会直播
  {
    path: `${langPath}/live`,
    component: LayoutEn,
    meta: { 
      title: "Live Streaming", 
      icon: "icon-icon_zhibo-xian",
      chkUserInfo: true,
     
    },
    lang: "en",
    // hidden: true,
    
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/en/Live/Index.vue"),
        meta: { title: "Live Streaming",},
        lang: "en",
      },
      {
        path: "info/:id(\\d+)",
        component: () => import("@/views/visitor/en/Live/Info.vue"),
        meta: { title: "Live Streaming",},
        hidden: true,
        lang: "en",
      },
      {
        path: "orders",
        component: () => import("@/views/visitor/en/Live/Orders.vue"),
        meta: { title: "My Order",},
        lang: "en",
      },
      {
        path: "freeticket",
        component: () => import("@/views/visitor/en/Live/Freeticket/Index.vue"),
        meta: { title: "Gift Tickets"},
        lang: "en",
      },
      {
        path: "freeticket/info/:give_id(\\d+)",
        component: () => import("@/views/visitor/en/Live/Freeticket/Info.vue"),
        meta: { title: "Gift Tickets",},
        hidden: true,
        lang: "en",
      },
      {
        path: "reservation",
        component: () => import("@/views/visitor/en/Live/Reservation.vue"),
        meta: { title: "My Subscription",},
        lang: "en",
      },
    ]
  },

  // 线下峰会
  {
    path: `${langPath}/onsite`,
    component: LayoutEn,
    meta: { 
      title: "Summit Tickets", 
      icon: "icon-outline-message-question",
      chkUserInfo: true,
    },
    hidden: true,
    lang: "en",
    children: [
      {
        path: "forums",
        component: () => import("@/views/visitor/en/Onsite/Index.vue"),
        meta: { title: "Summit Tickets",},
        lang: "en",
      },
      {
        path: "forums/:id(\\d+)",
        component: () => import("@/views/visitor/en/Onsite/Info.vue"),
        meta: { title: "Summit Tickets",},
        hidden: true,
        lang: "en",
      },
      {
        path: "orders",
        component: () => import("@/views/visitor/en/Onsite/Orders.vue"),
        meta: { title: "My Order",},
        lang: "en",
      },
      {
        path: "tickets",
        component: () => import("@/views/visitor/en/Onsite/Tickets.vue"),
        meta: { title: "My Tickets",},
        lang: "en",
      },

      {
        path: "freeticket",
        component: () => import("@/views/visitor/en/Onsite/Freeticket/Index.vue"),
        meta: { title: "Gift Tickets",},
        lang: "en",
      },
      {
        path: "freeticket/info/:ticket_id(\\d+)",
        component: () => import("@/views/visitor/en/Onsite/Freeticket/Info.vue"),
        meta: { title: "Gift Tickets",},
        hidden: true,
        lang: "en",
      },
    ]
  },

  // 白皮书下载
  {
    path: `${langPath}/download`,
    component: LayoutEn,
    meta: { 
      title: "White Paper Download", 
      icon: "icon-shu2",
      chkUserInfo: true,
      roles: ["visitor","ordinary"],
    },
    lang: "en",
    
    children: [
      {
        path: "",
        component: () => import("@/views/visitor/en/Download/Index.vue"),
        meta: { title: "White Paper Download",icon: "icon-shu2",},
        lang: "en",
      },
      {
        path: `${langPath}/mydownload`,
        component: () => import("@/views/visitor/en/Download/myDownload.vue"),
        meta: { title: "My Download",
       },
        hidden: true,
        lang: "en",
      },
    ]
  },

  // 展馆交通
  {
    path: `${langPath}`,
    lang: "en",
    children: [
      {
        path: "https://www.sialchina.com/contact/arrived.html",
        meta: { title: "Travel & Accomodation",icon: "icon-See",
        roles: ["visitor","ordinary"],
        lang: "en",
       },
      },
    ]
  },


  // 联系我们
  {
    path: `${langPath}`,
    lang: "en",
    children: [
      {
        path: "https://www.sialchina.com/contact/visitor-media.html",
        meta: { title: "Contact",icon: "icon-ziyuan",},
        lang: "en",
      },
    ]
  },
  
  // 访问不存在路由跳转到/
  {
    path: `/en:pathMatch(.*)*`,
    redirect: `/en`,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

export const asyncRoutes = currLang == 'en' ? asyncRoutesEn : asyncRoutesCn;
const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  base: "/"
});

// 白名单 name 值
const whiteList = ["login","reg","login2","reg2","login3","reg3","login4","reg4","regVip","regInvite","forget","regUniversal", "policy_cn",  "verification", "login_en","reg_en", "login_en2","reg_en2", "login_en3","reg_en3", "login_en4","reg_en4","regVip_en","regInvite_en","forget_en", "policy_en", "verification", "info_subscription", "info_subscription_en", "wechat", "wxbind", "logintest"];


// 添加权限路由
router.beforeEach(async (to, from, next) => {
  // 用户是否登录
  const isLogin = Cookies.get("isLogin");
  const path_home = to.path.indexOf("/en") >= 0 ? "/en" : "/"
  const path_login = to.path.indexOf("/en") >= 0 ? "/en/login" : "/login"

  if (isLogin) {
    console.log("已登录----------",to);
    //已登录
    if (/(\/visitor)?(\/en)?(\/login)/.test(to.path)) {
      // 重定向至首页
      next({ path: path_home });
    } else {
      const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      console.log("是否加载路由", hasRoles, store.getters.roles);
      if(hasRoles){
        if(/(\/visitor)?(\/en)?(\/reg)(?![_])(\/\d\w)?/.test(to.path)) {
          next({ path: path_home });
        }else {
          next();
        }
      }else {
        try {
          if(Cookies.get("vst_token") && currLang == "en") {
            await request({
              url: "/setVisitorToken",
              method: "post",
              data: {
                app_lang: currLang,
                "v-token": Cookies.get("vst_token"),
              },
            }).then(() => {
              }).catch(()=>{})
          }

          const roles = await store.dispatch("user/getInfo");
          console.log("加载路由-roles--",roles);
          store.dispatch("permission/generateRoutes", roles);
          next({ ...to, replace: true });
        } catch (error) {
          // 重新登录
          showMsg.error("请重新登录");
          await store.dispatch("user/logout");
          // next(`/login?redirect=${to.path}`);
          next(path_login);
        }
      }
    }
  } else {
    console.log("未登录----------");
    // 未登录
    if (whiteList.includes(to.name)) {
      next();
    } else {
      var liveReg = /(\/en)?\/eventlive\/*/;
      var forumticketReg = /(\/en)?\/forumticket\/*/;
      var payReg = /(\/en)?(\/alipay\/*)|(\/wxpay\/*)/;
      var ticketReg = /(\/en)?\/take-ticket\/*/;
      // 特殊情况
      if(liveReg.test(to.path) || forumticketReg.test(to.path) || payReg.test(to.path) || ticketReg.test(to.path)){
        next();
      }else {
        // next({ path: path_login, query: { redirect: to.path } });
        next({ path: path_login });
      }
      
    }
  }
});
 

export default router;
