<template>
  <Dialog
    ref="dialogRef"
    :dialogObject="dialogLoginOpt"
    @dialogClose="closeDialogLogin"
    class="dialog-auth"
  >
    <div class="memberauth-con login-con">
      <h2 class="title1">{{loginTitle}}</h2>
      <div class="login-type-switch" style="display: none;">
        <div class="login-type-btn" v-show="loginMethod == 1" @click="loginMethod = 2">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: inline;">
                <path d="M50 0H0L54 54V4C54 1.79086 52.2091 0 50 0Z" fill="#eee4e0"></path>
                <g clip-path="url(#clip0_2391_111115)">
                <mask id="mask0_2391_111115" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
                <path d="M50 0H0L54 54V4C54 1.79086 52.2091 0 50 0Z" fill="#eee4e0">
                </path></mask>
                <g mask="url(#mask0_2391_111115)">
                <path d="M31.3333 8H22V17.3333H31.3333V8Z" stroke="#d8806a" stroke-width="3" stroke-linejoin="round"></path>
                <path d="M31.3333 22.671H22V32.0044H31.3333V22.671Z" stroke="#d8806a" stroke-width="3" stroke-linejoin="round"></path>
                <path d="M46.0011 8H36.6677V17.3333H46.0011V8Z" stroke="#d8806a" stroke-width="3" stroke-linejoin="round"></path>
                <path d="M36.9707 22.6704V32.0037" stroke="#d8806a" stroke-width="2.8" stroke-linecap="round"></path>
                <path d="M41.3342 22.6704V32.0037" stroke="#d8806a" stroke-width="2.8" stroke-linecap="round"></path>
                <path d="M45.698 22.6704V32.0037" stroke="#d8806a" stroke-width="2.8" stroke-linecap="round"></path>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_2391_111115">
                <rect width="32" height="32" fill="white" transform="translate(18 4)">
                </rect></clipPath>
                </defs>
            </svg>

            <div class="tiptxt">扫码登录更安全</div>
        </div>

        <div class="login-type-btn" v-show="loginMethod == 2" @click="loginMethod = 1">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 0H0L54 54V4C54 1.79086 52.2091 0 50 0Z" fill="#eee4e0"></path>
                <mask id="mask0_2391_111769" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
                <path d="M50 0H0L54 54V4C54 1.79086 52.2091 0 50 0Z" fill="#eee4e0"></path>
                </mask>
                <g mask="url(#mask0_2391_111769)">
                <path d="M45.6316 7.5H22.3684C21.6127 7.5 21 8.20888 21 9.08333V24.9167C21 25.7911 21.6127 26.5 22.3684 26.5H45.6316C46.3873 26.5 47 25.7911 47 24.9167V9.08333C47 8.20888 46.3873 7.5 45.6316 7.5Z" stroke="#d8806a" stroke-width="3"></path>
                <path d="M27 32.5H41" stroke="#d8806a" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M32.5 12.5H35.5" stroke="#d8806a" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </svg>

            <div class="tiptxt">账号登录</div>
        </div>
      </div>

      <div v-show="loginMethod == 1">
        <el-form
          ref="formRef"
          :model="formModel"
          :rules="formRules"
          :hide-required-asterisk="true"
          class="login-formbox"
        >
          <div class="switch-tab">
            <div
              class="tab"
              :class="{'curr':formModel.type == 1}"
              @click="formModel.type = 1"
            >
              短信登录
            </div>
            <div
              class="tab"
              :class="{'curr':formModel.type != 1}"
              @click="formModel.type = 2"
            >
              账号登录
            </div>
          </div>

          <!-- 手机登录 -->
          <div v-show="formModel.type == 1">
            <el-form-item prop="account">
              <el-input
                type="text"
                v-model="formModel.account"
                suffix-icon="User"
                placeholder="请使用手机号登录"
                class="form-input"
              />
            </el-form-item>

            <el-form-item prop="vcode" class="code-box">
              <el-input
                type="text"
                v-model="formModel.vcode"
                placeholder="图片验证码"
                class="form-input"
                @keyup.enter="DoLogin"
              >
              <template #append>
                <img
                @click="getImgVcode"
                :src="imgVcode"
                id="ImgVcode"
                alt="看不清楚？点击可获取新的验证码。"
                class="imgvcode"
              />
              </template>
              </el-input>
            </el-form-item>

            <el-form-item prop="code" class="code-box">
              <el-input
                type="text"
                v-model="formModel.code"
                placeholder="手机验证码"
                class="form-input"
              >
              <template #append>
                <el-button @click="getSmsCode" class="codebtn" type="primary" :disabled="smscodeLoading">{{smscodeTxt}}</el-button>
              </template>
              </el-input>
            </el-form-item>
          </div>

          <!-- 账号登录 -->
          <div v-show="formModel.type != 1">
            <el-form-item>
              <el-radio-group
                v-model="formModel.type"
                prop="type"
              >
                <el-radio :label="2">中国大陆</el-radio>
                <el-radio :label="3">非中国大陆</el-radio>
              </el-radio-group>
            </el-form-item>

            <div v-show="formModel.type == 2">
              <el-form-item prop="account">
                <el-input
                  type="text"
                  v-model="formModel.account"
                  suffix-icon="User"
                  placeholder="请使用账号登录"
                  class="form-input"
                />
              </el-form-item>
            </div>

            <div v-show="formModel.type == 3">
              <el-form-item prop="account">
                <el-input
                  type="text"
                  v-model="formModel.account"
                  suffix-icon="User"
                  placeholder="请使用邮箱登录"
                  class="form-input"
                />
              </el-form-item>
            </div>

            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="formModel.password"
                suffix-icon="Key"
                placeholder="请输入密码"
                class="form-input"
                @keyup.enter="DoLogin"
              />
            </el-form-item>
          </div>
        </el-form>

        <div class="remember">
          <a class="reme-a" href="/forget" target="_blank">忘记密码？</a>
        </div>

        <div class="submit-box">
          <el-button type="primary" @click="DoLogin"
            >立即登录</el-button
          >
          <el-button type="primary" plain @click="toReg" v-if="!$store.state.close_reg">没有账号？点击马上注册</el-button>
        </div>
      </div>

      <div v-show="loginMethod == 2">
        <div class="ewm-login">
          <div class="img"></div>
          <p class="p">请使用微信扫码登录观众会员中心</p>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { reactive, toRefs, ref, unref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isMobile } from "@/utils/common";
import { sendSmsCode } from "@/api/common";
import showMsg from "@/utils/message";

import Dialog from "@/components/Dialog/Dialog.vue";

export default {
  components: {
    Dialog,
  },

  setup(props, ctx) {

    const store = useStore();

    const chkVcode = (rule, val, callback) => {
      if (state.formModel.type == 1 && !val) {
        callback(new Error("请输入图形验证码"));
      } 
      callback();
    };
    const chkSmscode = (rule, val, callback) => {
      if (state.formModel.type == 1 && !val) {
        callback(new Error("请输入手机验证码"));
      } 
      callback();
    };
    const chkAccount = (rule, val, callback) => {
      if (state.formModel.type == 1 && !val) {
        callback(new Error("请输入手机号"));
      } 
      if (state.formModel.type == 2 && !val) {
        callback(new Error("请输入账号"));
      } 
      if (state.formModel.type == 3 && !val) {
        callback(new Error("请输入邮箱"));
      } 
      callback();
    };
    const chkPassword = (rule, val, callback) => {
      if (state.formModel.type == 2 && !val) {
        callback(new Error("请输入密码"));
      } 
      callback();
    };

    const state = reactive({
      loginMethod: 1,// 1: 表单登录  2：二维码登录
      loginTitle: "会员登录",
      formModel: {
        type: 1,//1:短信登录 2:中国大陆 3：非中国大陆
        account: "",
        code: "",
        vcode: "",
        password: "",
        vcode_key: "",
      },
      formRules: {
        account: [{ validator: chkAccount, trigger: "blur" }],
        code: [{ validator: chkSmscode, trigger: "blur" }],
        vcode: [{ validator: chkVcode, trigger: "blur" }],
        password: [{ validator: chkPassword, trigger: "blur" }],
      },
      imgVcode: "",
      smscodeTxt: "获取手机验证码",
      smscodeLoading: false,

      // 登录
      dialogLoginOpt: {
        dialogVisible: store.getters.eventlive_show_login,
        width: "600px",
        isFooter: false,
        clickModal: false
      },
    });
    
    watch(()=>store.getters.eventlive_show_login,(newVal)=>{
      state.dialogLoginOpt.dialogVisible = newVal;
    })

    const closeDialogLogin = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",false);
    }

    // 获取图形验证码
    getImgVcode()
    function getImgVcode() {
      state.formModel.vcode_key = randomStr(36);
      state.imgVcode = `${store.state.api_domain}/visitor/getCaptchaImage?key=${state.formModel.vcode_key}`;
    }

    // 生成随机字符串
    function randomStr(len) {
      let t = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm1234567890";
      let val = "";
      for (let i = 0; i < len; i++) {
        val += t.charAt(Math.floor(Math.random() * t.length));
      }
      return val;
    }

    // 获取手机验证码
    function getSmsCode() {
      const mobile = state.formModel.account;
      const vcode = state.formModel.vcode;
      if (!isMobile(mobile)) {
        showMsg.error("请输入有效的手机号");
        return;
      }
      if (vcode?.length < 4) {
        showMsg.error("请先输入图形验证码");
        return;
      }
      state.smscodeLoading = true;
      state.smscodeTxt = "获取验证码中";
      sendSmsCode({ 
        mobile, send_type: 2,
        is_token: 1, 
        vcode,
        vcode_key: state.formModel.vcode_key
       })
        .then((res) => {
          const { data } = res;
          state.formModel.sms_token = data.token;

          var t = 60;
          var int = setInterval(function () {
            if (t == 0) {
              clearInterval(int);
              state.smscodeTxt = "获取手机验证码";
              state.smscodeLoading = false;
            } else {
              t--;
              state.smscodeTxt = `${t} s 后重新获取`;
              state.smscodeLoading = true;
            }
          }, 1000);
        })
        .catch((err) => {
          showMsg.error(err.msg);
          getImgVcode();
          state.smscodeTxt = "获取手机验证码";
          state.smscodeLoading = false;
        });
    }

    // 登录
    const formRef = ref(null);
    const DoLogin = () => {
      unref(formRef).validate((valid) => {
        if (valid) {

          state.loading = true;
          state.loadingTxt = "登录中...";
          store
            .dispatch("user/login", state.formModel)
            .then(() => {
              state.loading = false;
              state.loadingTxt = "";
              window.location.reload();
            })
            .catch((err) => {
              console.log('登录err',err);
              state.loading = false;
              state.loadingTxt = "";
              err.msg && showMsg.error(err.msg);
              getImgVcode();
            }); 
        }
      })
    }

    const toReg = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",false);
      store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",true);
    }

    return {
      ...toRefs(state),
      closeDialogLogin,
      formRef,
      getImgVcode,
      getSmsCode,
      DoLogin,
      toReg
    };
  },
};
</script>

<style lang="less" scoped>
</style>
