import request from "@/utils/request";
import { getLoginStatus, setLoginStatus, removeLoginStatus } from "@/utils/auth";
import Cookies from "js-cookie";

const state = {
  isLogin: Cookies.get("isLogin"),
  truename: Cookies.get("truename"),
  roles: [],
  is_need_completion_certificates: false, // 是否弹出提示补全身份证
  is_need_completion_survey: false,//是否弹出提示提交问卷
  expo_list: [], //展会信息
};
const mutations = {
  SET_STATUS: (state, data) => {
    state.isLogin = data;
  },
  SET_TRUENAME: (state, data) => {
    state.truename = data;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_NEED_CERTIFICATES: (state, data) => {
    state.is_need_completion_certificates = data;
  },
  SET_NEED_survey: (state, data) => {
    state.is_need_completion_survey = data;
  },
  SET_EXPO_LIST: (state, data) => {
    state.expo_list = data;
  },
};

const actions = {
  // 登录
  login({ commit }, userInfo) {
    const { app_lang } = userInfo;
    return new Promise((resolve, reject) => {
      request({
        url: "/login",
        method: "post",
        data: userInfo,
      })
        .then((response) => {
          console.log("store-登录-response",response);
          const { data } = response;
            // 存放令牌状态
            commit("SET_STATUS", true);
            setLoginStatus(true);
            if(app_lang == "en") {
              const expire_time = new Date().getTime() + (1*24*60*60*1000);
              document.cookie = `isLogin=1; expires=${new Date(expire_time).toUTCString()}; path=/; domain=.sialchina.com;`;
            }
            commit("SET_TRUENAME", data.truename);
            Cookies.set("truename",data.truename);
            
            resolve();
        })
        .catch((error) => {
          console.log("store-登录-catch",error);
          reject(error);
        });
    });
  },

  // 获取角色
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      request({
        url: "/getExpoAreaStatus",
        method: "GET",
        params: "",
      })
        .then(response => {
          const { data } = response;
          
          var roles;
          if(data.visitor_type == 0) {
            roles = "ordinary";
          }else if(data.visitor_type == 5){
            roles = "vip";
          }else {
            roles = "visitor";
          }
          commit("SET_ROLES", roles);
          commit("SET_NEED_CERTIFICATES", data.is_need_completion_certificates);
          commit("SET_NEED_survey", data.is_need_completion_survey);
          commit("SET_EXPO_LIST",data.expo_list);
          
          resolve(roles);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 更改角色
  async changeRoles({ commit, dispatch }, role) {
    // 重新获取用户角色
    const roles = await dispatch("getInfo");
    console.log("-changeRoles--",roles);

    // 重置路由为初始状态
    dispatch("permission/resetRoutes", null, { root: true });

    // 根据角色生成可访问路由映射
    const accessRoutes = await dispatch("permission/generateRoutes", roles, {
      root: true,
    });
    // 动态添加可访问路由
    dispatch("permission/addRoutes", accessRoutes, {
      root: true,
    });
  },


  // 退出登录
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      request({
        url: "/logout",
        method: "post",
        data: "",
      })
        .then(() => {
          // 清除令牌状态
          commit("SET_STATUS", false);
          commit("SET_ROLES", []);
          commit("SET_TRUENAME", null);
          removeLoginStatus();
          Cookies.remove("truename");

          // 删除英文主站登录的信息
          if(window.location.hostname == "visitor.sialchina.com" || window.location.pathname.indexOf("/en") == 0) {
            document.cookie = `isLogin=; expires=${new Date(0).toUTCString()}; path=/; domain=.sialchina.com;`;
            document.cookie = `isLogin=; expires=${new Date(0).toUTCString()}; path=/; domain=www.sialchina.com;`;
            document.cookie = `isLogin=; expires=${new Date(0).toUTCString()}; path=/; domain=visitor.sialchina.com;`;
            document.cookie = `truename=; expires=${new Date(0).toUTCString()}; path=/; domain=.sialchina.com;`;
            document.cookie = `truename=; expires=${new Date(0).toUTCString()}; path=/; domain=www.sialchina.com;`;
            document.cookie = `vst_token=; expires=${new Date(0).toUTCString()}; path=/; domain=.sialchina.com;`;
            document.cookie = `vst_token=; expires=${new Date(0).toUTCString()}; path=/; domain=www.sialchina.com;`;
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
