<template>
  <Dialog
    ref="dialogRef"
    :dialogObject="dialogLoginOpt"
    @dialogClose="closeDialogLogin"
    class="dialog-auth"
  >
    <div class="memberauth-con login-con">
      <h2 class="title1">{{loginTitle}}</h2>

      <div>
        <el-form
          ref="formRef"
          :model="formModel"
          :rules="formRules"
          :hide-required-asterisk="true"
          class="login-formbox"
        >

            <el-form-item prop="account">
              <el-input
                type="text"
                v-model="formModel.account"
                suffix-icon="User"
                placeholder="Please use email to login"
                class="form-input"
              />
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="formModel.password"
                suffix-icon="Key"
                placeholder="Please enter password"
                class="form-input"
                @keyup.enter="DoLogin"
              />
            </el-form-item>
        </el-form>

        <div class="remember">
          <a class="reme-a" :href="`${$t('baseurl')}/forget`" target="_blank">Forgot your password?</a>
        </div>

        <div class="submit-box">
          <el-button type="primary" @click="DoLogin"
            >Login</el-button
          >
          <el-button type="primary" plain @click="toReg" v-if="!$store.state.close_reg">Not registered yet? Start now!</el-button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { reactive, toRefs, ref, unref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isMobile } from "@/utils/common";
import { sendSmsCode } from "@/api/common";
import showMsg from "@/utils/message";

import Dialog from "@/components/Dialog/Dialog.vue";

export default {
  components: {
    Dialog,
  },

  setup(props, ctx) {

    const store = useStore();

    const state = reactive({
      loginTitle: "Visitor Member Login",
      formModel: {
        app_lang: "en",
        type: 3,//1:短信登录 2:中国大陆 3：非中国大陆
        account: "",
        code: "",
        vcode: "",
        password: "",
        vcode_key: "",
      },
      formRules: {
        account: [{  required: true, trigger: "blur" }],
        password: [{ required: true, trigger: "blur" }],
      },

      // 登录
      dialogLoginOpt: {
        dialogVisible: store.getters.eventlive_show_login,
        width: "600px",
        isFooter: false,
        clickModal: false
      },
    });
    
    watch(()=>store.getters.eventlive_show_login,(newVal)=>{
      state.dialogLoginOpt.dialogVisible = newVal;
    })

    const closeDialogLogin = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",false);
    }

    // 获取图形验证码
    getImgVcode()
    function getImgVcode() {
      state.formModel.vcode_key = randomStr(36);
      state.imgVcode = `${store.state.api_domain}/visitor/getCaptchaImage?key=${state.formModel.vcode_key}`;
    }

    // 生成随机字符串
    function randomStr(len) {
      let t = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm1234567890";
      let val = "";
      for (let i = 0; i < len; i++) {
        val += t.charAt(Math.floor(Math.random() * t.length));
      }
      return val;
    }

    // 登录
    const formRef = ref(null);
    const DoLogin = () => {
      unref(formRef).validate((valid) => {
        if (valid) {

          state.loading = true;
          state.loadingTxt = "logging...";
          store
            .dispatch("user/login", state.formModel)
            .then(() => {
              state.loading = false;
              state.loadingTxt = "";
              window.location.reload();
            })
            .catch((err) => {
              console.log('登录err',err);
              state.loading = false;
              state.loadingTxt = "";
              err.msg && showMsg.error(err.msg);
              getImgVcode();
            }); 
        }
      })
    }

    const toReg = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",false);
      store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",true);
    }

    return {
      ...toRefs(state),
      closeDialogLogin,
      formRef,
      DoLogin,
      toReg
    };
  },
};
</script>

<style lang="less" scoped>
</style>
