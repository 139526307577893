//en.js
const hostname = window.location.hostname;
console.log("hostname",hostname);
const en = {
    "baseurl": hostname == "visitor.sialchina.com" ? "" : "/en",
    "lang": "en",
    "head_title": "sial shanghai|sial shenzhen-Visitor Member Center-SIAL China",
    "head_description": "SIAL China is a comprehensive food and beverage exhibition dedicated to Asia and even the world. It will be held from May 28th to 30th, 2024 at the Shanghai Pudong New International Expo Center, and from September 2nd to 4th, 2024 at the Shenzhen Convention and Exhibition Center (Futian). Visitors are welcome to register at the Visitor Member Center.",
    "head_keywords": "sial shanghai,sial shenzhen,SIAL China",
    "￥": "$",
    "home": "Home",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "please select payment": "Please select a payment method",
    "select payment": "Select payment method",
    "paid success": "Paid success",
    "paid successfully": "Paid successfully",
    "none payment": "Unpaid",
    "paid": "Paid",
    "Wechat Pay": "Wechat Pay",
    "Alipay Pay": "Alipay Pay",
    "Offline payment": "Bank Transfer",
    "coupon": "Coupon",
    "input coupon": "Please enter your promotion code",
    "submit payment": "Immediate payment",
    "open Alipay payment interface": "Please pay in the open Alipay payment interface",
    "Bank Transfer Information": "Bank Transfer Information",
    "paying": "Paying...",
    "Pay with a swipe on wechat": "Pay with a swipe on wechat",
    "Tickets purchased successfully, please pay offline": "Tickets purchased successfully, please pay offline",
    "You have paid successfully, to My Ticket": 'You have paid successfully, please check the admission code on "My Ticket"!',
    "Warm reminder": "Warm reminder",
    "payment within 2 hours": "Please complete the payment within 2 hours, the order will be automatically cancelled.",
    "Order information": "Order information",
    "Order number": "Order number",
    "truename": "Truename",
    "mobile": "Mobile",
    "email": "Email",
    "number": "Number",
    "unit price": "Unit price",
    "total order price": "Total order price",
    "Discount amount": "Discount amount",
    "Amount actually paid": "Amount actually paid",
    "View order": "View order",
    "live time": "live time",
    "Please log in": "Please log in",
    "Log back in": "Log in",
    "The request timed out. Please refresh and try again": "The request timed out. Please refresh and try again",
    'date': {
        'D': 'Days',
        'h': 'hours',
        'm': 'Minutes',
        's': 'Seconds',
    },
    "livepay tips": "The video is valid for 21 days after purchase"
}
 
export default en