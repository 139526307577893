<template>
  <Dialog
    ref="dialogRef"
    :dialogObject="dialogLoginOpt"
    @dialogClose="closeDialogLogin"
    class="dialog-auth"
  >
    <div class="memberauth-con login-con">
      <h2 class="title1">手机号授权</h2>

      <div>
        <el-form
          ref="formRef"
          :model="formModel"
          :rules="formRules"
          :hide-required-asterisk="true"
          class="login-formbox"
        >
            <el-form-item prop="mobile">
              <el-input
                type="text"
                v-model="formModel.mobile"
                suffix-icon="User"
                placeholder="请使用手机号登录"
                class="form-input"
              />
            </el-form-item>

            <el-form-item prop="code" class="code-box">
              <el-input
                type="text"
                v-model="formModel.code"
                placeholder="手机验证码"
                class="form-input"
              >
              <template #append>
                <el-button @click="getSmsCode" class="codebtn" type="primary" :disabled="smscodeLoading">{{smscodeTxt}}</el-button>
              </template>
              </el-input>
            </el-form-item>

        </el-form>

        <div class="submit-box">
          <el-button type="primary" class="btn-primary2" :loading="loading" @click="bindWx"
              >立即绑定</el-button
            >
            <el-button type="primary" plain @click="toReg" v-if="!$store.state.close_reg">没有账号？点击马上注册</el-button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { ElMessageBox } from "element-plus";
import { reactive, toRefs, ref, unref, watch, watchEffect, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { isMobile } from "@/utils/common";
  import { sendSmsCode, wxBind, wxLogin } from "@/api/common";
import showMsg from "@/utils/message";
import Cookies from "js-cookie";

import Dialog from "@/components/Dialog/Dialog.vue";

export default {
  components: {
    Dialog,
  },

  setup(props, ctx) {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    

    const state = reactive({
      isShowForm: false,
      formModel: {
          mobile: "",
          code: "",
        },
        formRules: {
          mobile: [
            { required: true, trigger: "blur", message: "请输入手机号" },
            { 
              validator: (rule, val) => {
                if(!isMobile(val)) {
                  return Promise.reject('请输入有效的手机号');
                }else {
                  return Promise.resolve();
                }
              }, 
              trigger: ["change", "blur"] 
            }
          ],
          code: [{ required: true, trigger: "blur",  message: "请输入手机验证码" }],
        },
      smscodeTxt: "获取手机验证码",
      smscodeLoading: false,

      // 登录
      dialogLoginOpt: {
        dialogVisible: false,
        width: "600px",
        isFooter: false,
        clickModal: false
      },
      loading: false,
    });
    
    watch(()=>store.getters.eventlive_show_login,(newVal)=>{
      if(newVal) {
        if(state.isShowForm) {
          state.dialogLoginOpt.dialogVisible = newVal;
        }else {
          mbWxLogin();
        }
      }else {
        state.dialogLoginOpt.dialogVisible = newVal;
      }
      
    },{immediate: true})

    // 重定向处理
    const redirect = ref("");
      watchEffect(() => {
        redirect.value = route.query.redirect && decodeURIComponent(route.query.redirect);
      });

      

    const mbWxLogin = ()=>{
      // 返回页不能是详情页，会进入死循环(详情页判断登录状态)
      var url = route.fullPath;
      if(url.indexOf("/info") != -1) {
        url = `/eventlive?redirect=${route.fullPath}`
      }
      window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx328b19835e4ac06e&redirect_uri=${encodeURIComponent(`https://visitor.sialchina.cn${url}`)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
    }

    onBeforeMount(() => {
      if(route.query.code) {
        wxLogin({code: route.query.code}).then(async (res)=>{
          const {data} = res;
          if(data.is_visitor == 1) {
            // 已绑定

            Cookies.set("isLogin", true,   { expires: 1 });
            Cookies.set("truename", data.truename,  { expires: 1 });
            store.commit("user/SET_STATUS", true);
            store.commit("user/SET_TRUENAME", data.truename);

            router.push({ path: unref(redirect) || route.fullPath });
          }else if(data.is_visitor == 0) {
            // 未绑定
            showMsg.warning("请先进行微信绑定账号");

            // ElMessageBox.alert("请先进行微信绑定账号").then(()=>{
              if(!store.getters.eventlive_show_login) {
                store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",true);
              }else {
                state.dialogLoginOpt.dialogVisible = true;
              }
              state.isShowForm = true;
            // })
            
          }
        }).catch((err)=>{
          showMsg.error(err.msg)
          // ElMessageBox.alert(`微信绑定失败,${err.msg}`).then(()=>{
            // router.push("/eventlive")
            window.location.href="/eventlive"
          // })
          
        })
      }
    })

    const closeDialogLogin = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",false);
    }

    // 获取手机验证码
    function getSmsCode() {
      const mobile = state.formModel.mobile;

      if (!isMobile(mobile)) {
        showMsg.error("请输入有效的手机号");
        return;
      }

      sendSmsCode({ mobile, send_type: 6 })
        .then((res) => {
          var t = 60;
          var int = setInterval(function () {
            if (t == 0) {
              clearInterval(int);
              state.smscodeTxt = "获取手机验证码";
              state.smscodeLoading = false;
            } else {
              t--;
              state.smscodeTxt = `${t} s 后重新获取`;
              state.smscodeLoading = true;
            }
          }, 1000);
        })
        .catch((err) => {
          showMsg.error(err.msg);
        });
    }

    // 登录
    const formRef = ref(null);
    const bindWx = () => {
        unref(formRef).validate((valid) => {
          if (valid) {
  
            state.loading = true;
            state.loadingTxt = "绑定中...";
            
            wxBind(state.formModel).then((res)=>{
              const {data} = res;

              Cookies.set("isLogin", true,  { expires: 1 });
              Cookies.set("truename", data.truename,  { expires: 1 });
              store.commit("user/SET_STATUS", true);
              store.commit("user/SET_TRUENAME", state.formModel.truename);
             
              showMsg.success(`绑定成功，已登录`,{},()=>{
                // window.location.reload();
                store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",false);
                router.push({ path: unref(redirect) || route.fullPath });
              });
            }).catch((err)=>{
              if(err.code == 10002) {
                showMsg.error("绑定失败，请重新绑定",{},()=>{
                  const url = `https://visitor.sialchina.cn${route.fullPath}`;
                  window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx328b19835e4ac06e&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
                });
                
              }else {
                showMsg.error(err.msg);
              }
              
              state.loading = false;
            })
          }
        })
      };

    const toReg = ()=>{
      store.commit("eventlive/SET_EVENTLIVE_SHOW_LOGIN",false);
      store.commit("eventlive/SET_EVENTLIVE_SHOW_REG",true);
    }

    return {
      ...toRefs(state),
      closeDialogLogin,
      formRef,
      getSmsCode,
      bindWx,
      toReg
    };
  },
};
</script>

<style lang="less" scoped>
</style>
