
import { getExpoAreaList } from "@/api/common";

/**
 * 获取展会列表
 */
 export function getAreaList() {
  return new Promise((resolve, reject) => {
    getExpoAreaList().then((res)=>{
      resolve(res)
    }).catch((err)=>{
      reject(err)
    })
  })
}


export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

export function timeFormat(timestamp) {
  if (!timestamp) {
    return timestamp;
  }

  var value = parseInt(timestamp).length < 13 ? parseInt(timestamp) * 1000 : parseInt(timestamp);
  var time = new Date(value);
  const utc = time.getTime() + time.getTimezoneOffset() * 60000;
  const iaDate = new Date(utc + 480 * 60000); // 480 minutes = 8 hours

  var year = iaDate.getFullYear();
  var month = iaDate.getMonth() + 1;
  var date = iaDate.getDate();
  var hour = iaDate.getHours();
  var minute = iaDate.getMinutes();
  var second = iaDate.getSeconds();
  month = month < 10 ? "0" + month : month;
  date = date < 10 ? "0" + date : date;
  hour = hour < 10 ? "0" + hour : hour;
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return (
    year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
  );
}

/**
 * 获取北京时间时间戳
 * @param {*} timestamp (毫秒)
 * @returns 
 */
export function getBeijingTimestamp(timestamp) {
  var time = timestamp || new Date().getTime();
  return new Date(timeFormat(time)).getTime();
}

/**
 * 检查是否手机号码
 * @param {*} mobile 
 * @returns {Boolean}
 */
export function isMobile(mobile) {
    var reg = /^1[3-9]\d{9}$/;
    return reg.test(mobile);
}

/**
 * 检查是否邮箱
 * @param {*} email 
 * @returns {Boolean}
 */
export function isEmail(email) {
    var reg = /^[\w\+\-]+(\.[\w\+\-]+)*@[a-z\d\-]+(\.[a-z\d\-]+)*\.([a-z]{2,4})$/i;
    return reg.test(email);
}

/**
 *  判断密码：字母、数字、特殊符号任意两种8-16位组合
 * @param {*}  
 * @returns {}
 */
export function isPassword(password) {
  var reg = /^(?=.*[0-9\W_])(?=.*[\W_]).{8,16}|(?=.*[a-zA-Z])(?=.*[0-9\W_]).{8,16}|(?=.*[a-zA-Z])(?=.*[\W_]).{8,16}$/;
  return reg.test(password);
}


/**
 *  滚动到指定位置
 * @param {*}  
 * @returns {}
 */
 export function scrollToTarget(obj) {
  const targetElement = document.querySelector(obj);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
}


/**
 *  获取证件类型-静态数据
 * @param {*}  
 * @returns {}
 */
export function getCertificatesType() {
  return [
    {
      "id": 1,
      "name": "身份证",
      "name_en": ""
    },
    {
      "id": 2,
      "name": "护照",
      "name_en": "Passport",
    },
    {
      "id": 3,
      "name": "港澳居民来往内地通行证",
      "name_en": "Mainland Travel Permit for Hong Kong and Macao Residents"
    },
    {
      "id": 4,
      "name": "台湾居民来往大陆通行证",
      "name_en": "Mainland Travel Permit for Taiwan Residents"
    },
    {
      "id": 5,
      "name": "港澳台居民居住证",
      "name_en": "Residence Permit for Hong Kong, Macao and Taiwan Residents"
    },
    {
      "id": 6,
      "name": "外国人永久居留身份证",
      "name_en": "Foreign Permanent Resident ID Card"
    },
  ]
}


/**
 *  身份类型
 * @param {*}  
 * @returns {}
 */
export function visitorTypeText(id) {
  switch (id) {
    case 1:
      return {
        name_cn: "专业观众",
        name_en: "Visitor",
      };
      
    case 2:
      return {
        name_cn: "媒体观众",
        name_en: "Media",
      };

    case 3:
      return {
        name_cn: "特邀买家",
        name_en: "Match Me Buyers",
      };

    case 4:
      return {
        name_cn: "会议观众",
        name_en: "Attendee",
      };

    case 5:
      return {
        name_cn: "VIP观众",
        name_en: "VIP",
      };

    case 6:
      return {
        name_cn: "组团观众",
        name_en: "Visitor Delegation",
      };
    
    default: 
      return {
        name_cn: "",
        name_en: "",
      };
  }
}

/**
 *  支付方式
 * @param {*}  
 * @returns {}
 */
 export function payMethod(v) {
  switch (v) {
    case "wxpay":
      return {
          name_cn: "微信",
          name_en: "Wechat",
        }
      
    case "alipay":
      return {
        name_cn: "支付宝",
        name_en: "Alipay",
      };

    case "bank":
      return {
        name_cn: "线下付款",
        name_en: "Offline payment",
      };

    case "coupon":
      return {
        name_cn: "优惠券支付",
        name_en: "Coupon payment",
      };

    case "free":
      return {
        name_cn: "免费门票",
        name_en: "Free ticket",
      };

    case "admin":
      return {
        name_cn: "管理员操作",
        name_en: "Administrator operation",
      };

    case "give":
      return {
        name_cn: "主办方赠票",
        name_en: "Sponsor complimentary ticket",
      };
    
    default: 
      return {
        name_cn: "",
        name_en: "",
      };
    }
 }


/**
 *  订单状态 
 * -1 已取消 -2已退款 1待付款 2已付款 3待确认 4已核销
 * @param {*}  
 * @returns {}
 */
 export function payStatusName(v) {
  switch (v) {
    case -1:
      return {
          name_cn: "取消",
          name_en: "Cancelled",
        }
      
    case -2:
      return {
        name_cn: "已退款",
        name_en: "Refunded",
      };

    case 1:
      return {
        name_cn: "待付款",
        name_en: "Unpaid",
      };

    case 2:
      return {
        name_cn: "已付款",
        name_en: "Paid",
      };

    case 3:
      return {
        name_cn: "待确认",
        name_en: "To be confirmed",
      };

    case 4:
      return {
        name_cn: "已核销",
        name_en: "Verified",
      };
    
    default: 
      return {
        name_cn: "",
        name_en: "",
      };
    }
 }


/**
 *  日期格式
 * @param {*}  
 * @returns {}
 */
export function formatTime(number,format) {  
  
  var formateArr  = ['Y','M','D','h','m','s'];  
  var returnArr   = [];  
  
  var date;  
  if(String(number).length < 13) {
    date = new Date(number * 1000);  
  }else {
    date = new Date(number);  
  }

  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  const iaDate = new Date(utc + 480 * 60000); // 480 minutes = 8 hours
  returnArr.push(iaDate.getFullYear());  
  returnArr.push(formatNumber(iaDate.getMonth() + 1));  
  returnArr.push(formatNumber(iaDate.getDate()));  
  
  returnArr.push(formatNumber(iaDate.getHours()));  
  returnArr.push(formatNumber(iaDate.getMinutes()));  
  returnArr.push(formatNumber(iaDate.getSeconds()));  
  
  for (var i in returnArr)  
  {  
    format = format.replace(formateArr[i], returnArr[i]);  
  }  
  return format;  
} 

//数据转化  
function formatNumber(n) {  
  n = n.toString()  
  return n[1] ? n : '0' + n  
} 

export function convertToUTCTimestamp(dateStr) {
  // 解析日期字符串为Date对象，注意这里的日期字符串格式需严格匹配
  var localDate = new Date(dateStr + 'T00:00:00'); // 假定时间为当天零点

  // 获取本地时间偏移量，单位为分钟。此值为负表示位于UTC西侧，正则位于东侧
  var offsetInMinutes = localDate.getTimezoneOffset();

  // 调整时间以抵消时区偏移，转换为UTC时间
  // 因为getTimezoneOffset返回的是与UTC的偏移，东时区为正，所以这里需要减去
  var utcDate = new Date(localDate.getTime() - (offsetInMinutes * 60000));

  // 返回UTC时间的时间戳
  return utcDate.getTime();
}

/**
 * 两个日期合并
 */
export function rangeFormatTime(start, end, format) {
  var end_date, start_date;
  if(String(start).length < 13) {
    start_date = new Date(start * 1000);  
  }else {
    start_date = new Date(start);  
  }
  if(String(end).length < 13) {
    end_date = new Date(end * 1000);  
  }else {
    end_date = new Date(end);  
  }
  
  const start_utc = start_date.getTime() + start_date.getTimezoneOffset() * 60000;
  const ia_start_date = new Date(start_utc + 480 * 60000); // 480 minutes = 8 hours

  const end_utc = end_date.getTime() + end_date.getTimezoneOffset() * 60000;
  const ia_end_date = new Date(end_utc + 480 * 60000); // 480 minutes = 8 hours

  if(ia_start_date.getFullYear() != ia_end_date.getFullYear()) {
    // 不在同一年份
    return `${formatTime(start,format)} - ${formatTime(end,format)}`
  }
  if((ia_start_date.getMonth() + 1) != (ia_end_date.getMonth() + 1)) {
    // 不在同个月份
    var monthFormat = format.substring(2);
    return `${formatTime(start,format)}-${formatTime(end,monthFormat)}`
  }

  if(ia_start_date.getDay() != ia_end_date.getDay()) {
    // 不在同一日
    var dayFormat = format.substring(4);
    return `${formatTime(start,format)}-${formatTime(end,dayFormat)}`
  }
  
  var timeFormat = format.substring(6);
  return `${formatTime(start,format)}-${formatTime(end,timeFormat)}`
}

export function calculateAge(birthday) {
  var today = new Date();
  var birthDate = new Date(birthday);
  const today_utc = today.getTime() + today.getTimezoneOffset() * 60000;
  const ia_today = new Date(today_utc + 480 * 60000); // 480 minutes = 8 hours

  const birth_utc = birthDate.getTime() + birthDate.getTimezoneOffset() * 60000;
  const ia_birth = new Date(birth_utc + 480 * 60000); // 480 minutes = 8 hours

  var age = ia_today.getFullYear() - ia_birth.getFullYear();
  var monthDifference = ia_today.getMonth() - ia_birth.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && ia_today.getDate() < ia_birth.getDate())) {
    age--;
  }
  return age;
}

// 生成随机字符串
export function randomStr(len) {
  let t = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm1234567890";
  let val = "";
  for (let i = 0; i < len; i++) {
    val += t.charAt(Math.floor(Math.random() * t.length));
  }
  return val;
}


// 英文国家筛选-以该字母开始的国家地区
export function selectRemoteMethod(list, query, allData) {
  if(query) {
    return list.filter((item) => {
      return item.name_en.toLowerCase().indexOf(query.toLowerCase()) == 0
    })
  }else {
    return allData;
  }
}


// 当前环境
export function getUserAgent() {
  const userAgent = navigator.userAgent.toLowerCase();
  if(/micromessenger/.test(userAgent)) {
    return "wx"
  }else if(/alipayclient|aliapp/.test(userAgent)) {
    return "ali"
  }else {
    return "pc"
  }
}